import { uploadDocAPI } from 'services/workspaceAPI/';
import client from 'services/workspaceAPI/client';
import { Dispatch, ApplicationStateGetter, AsyncAction } from 'store';
import { addToast } from 'store/ui/actions';
import { windowUnloadManager } from 'utils/events/windowUnloadManager';
import { getOrderDocuments } from '.';
import {
  OrderTypeKeys,
  IStagedDocumentUpload,
  IOrderClearActiveUploadsAction,
  IDocumentUpload,
  IOrderDocumentRemoveFromCacheAction,
  IOrderDocumentSetRemovingAction,
  IDocument,
  IOrderStageDocumentsAction,
  IOrderClearStagedDocumentsAction,
} from '../types';
import { generateInternalDocumentName } from 'utils/orderDocuments';
import { isInternalUser } from 'store/auth/getters';

const uploadUnloadKey = 'UPLOAD_IN_PROGRESS';
const uploadUnloadHandler = () => 'One or more uploads are in progress';

export const clearActiveDocumentUploads = (): IOrderClearActiveUploadsAction => ({
  type: OrderTypeKeys.UI_CLEAR_ACTIVE_UPLOAD_DOCUMENTS,
});

export const clearStagedDocumentUploads = (): IOrderClearStagedDocumentsAction => ({
  type: OrderTypeKeys.UI_CLEAR_STAGED_DOCUMENT_UPLOADS,
});

export const stageDocumentUploads = (
  documentsToStage: IStagedDocumentUpload[]
): IOrderStageDocumentsAction => ({
  type: OrderTypeKeys.UI_STAGE_DOCUMENT_UPLOADS,
  documentsToStage,
});

const executeCustomerDocumentUpload = (
  upload: IDocumentUpload,
  fileName: string
) => {
  return async (dispatch) => {
    const { customerDocumentId, file, metadata, fileID } = upload;
    const form = new FormData();
    form.append('File', file);
    if (customerDocumentId) {
      form.append('CustomerDocumentId', customerDocumentId);
    }

    if (metadata.displayName) {
      form.append('DisplayName', metadata.displayName);
    }

    if (metadata.notes) {
      form.append('Notes', metadata.notes);
    }

    if (metadata.secured) {
      form.append('Secured', JSON.stringify(metadata.secured));
    }

    return uploadDocAPI({ 'Content-Type': 'multipart/form-data' }).post(
      `/orders/${fileID}/customerdocuments/upload`,
      form,
      {
        onUploadProgress: (e) => {
          dispatch({
            type: OrderTypeKeys.UI_UPDATE_DOC_UPLOAD_PROGRESS,
            progress: Math.round((e.loaded * 100) / e.total),
            fileName: fileName,
          });
        },
      }
    );
  };
};

const executeDocumentUpload = (upload: IDocumentUpload, fileName: string) => {
  return async (dispatch) => {
    const { file, metadata, fileID } = upload;
    const form = new FormData();
    form.append('File', file);
    form.append('Type', metadata.type.split('#')[0]);
    form.append('SubType', generateInternalDocumentName(upload));

    if (metadata.displayName) {
      form.append('DisplayName', metadata.displayName);
    }

    if (metadata.notes) {
      form.append('Notes', metadata.notes);
    }

    if (metadata.sdnHitId) {
      form.append('sdnHitId', metadata.sdnHitId.toString());
    }

    return uploadDocAPI({ 'Content-Type': 'multipart/form-data' }).post(
      `/orders/${fileID}/documents`,
      form,
      {
        onUploadProgress: (e) => {
          dispatch({
            type: OrderTypeKeys.UI_UPDATE_DOC_UPLOAD_PROGRESS,
            progress: Math.round((e.loaded * 100) / e.total),
            fileName: fileName,
          });
        },
      }
    );
  };
};

const executeDepositUpload = (upload: IDocumentUpload, fileName: string) => {
  return async (dispatch) => {
    const { file, metadata, fileID } = upload;
    const form = new FormData();
    form.append('File', file);
    form.append('Type', metadata.type.split('#')[0]);
    form.append('SubType', generateInternalDocumentName(upload));

    if (metadata.displayName) {
      form.append('DisplayName', metadata.displayName);
    }

    if (metadata.notes) {
      form.append('Notes', metadata.notes);
    }

    return uploadDocAPI({ 'Content-Type': 'multipart/form-data' }).post(
      `/orders/${fileID}/deposits/${metadata.depositId}/upload`,
      form,
      {
        onUploadProgress: (e) => {
          dispatch({
            type: OrderTypeKeys.UI_UPDATE_DOC_UPLOAD_PROGRESS,
            progress: Math.round((e.loaded * 100) / e.total),
            fileName: fileName,
          });
        },
      }
    );
  };
};

const executeDocumentUploads = async (
  dispatch: Dispatch,
  getState: ApplicationStateGetter
): Promise<Set<string>> => {
  const affectedOrders = new Set<string>();

  const uploadQueue = getState().orders.ui.multiDocumentsUpload
    .inProgressUploads;
  let error;

  const uniqueUploadsQueue = uploadQueue.filter((doc) => doc.progress === 0);

  const promises: any[] = [];
  uniqueUploadsQueue.forEach((file) => {
    if (!isInternalUser(getState().auth)) {
      promises.push(
        handleDocumentUploadResponse(
          executeCustomerDocumentUpload(file, file.file.name)
        )
      );
    } else if (file.metadata.depositId) {
      promises.push(
        handleDocumentUploadResponse(executeDepositUpload(file, file.file.name))
      );
    } else {
      promises.push(
        handleDocumentUploadResponse(
          executeDocumentUpload(file, file.file.name)
        )
      );
    }

    function handleDocumentUploadResponse(executeUpload: any) {
      return dispatch(executeUpload)
        .then(() => {
          dispatch({
            error,
            type: OrderTypeKeys.UI_COMPLETE_ACTIVE_DOCUMENT_UPLOAD,
            fileName: file.file.name,
          });
          affectedOrders.add(file.fileID);
        })
        .catch((error) => {
          dispatch({
            error,
            type: OrderTypeKeys.UI_COMPLETE_ACTIVE_DOCUMENT_UPLOAD,
            fileName: file.file.name,
          });
        });
    }
  });
  await Promise.all(promises);
  return affectedOrders;
};

export const uploadStagedDocuments = (
  stagedDocuments: IStagedDocumentUpload[]
) => {
  return async (dispatch: Dispatch, getState: ApplicationStateGetter) => {
    dispatch({
      type: OrderTypeKeys.UI_QUEUE_ORDER_DOCUMENT_UPLOAD,
      stagedDocuments,
    });

    windowUnloadManager.registerHandler(uploadUnloadKey, uploadUnloadHandler);
    const affectedOrders = await executeDocumentUploads(dispatch, getState);
    windowUnloadManager.unregisterHandler(uploadUnloadKey);

    const currentOrder = window.location.pathname.match(
      new RegExp('/order/([0-9]+)/documents/?$')
    )?.[1];

    if (currentOrder && affectedOrders.has(currentOrder)) {
      dispatch(getOrderDocuments(currentOrder));
    }
  };
};

/**
 *
 * @param orderId - file id
 * @param docId - document id or template instance id
 * @param isDisplayNameEnabled
 * @param isTemplateInstance - is the request for template instance
 */
export function removeOrderDocument(
  orderId: number,
  docId: number,
  isDisplayNameEnabled: boolean,
  isTemplateInstance?: boolean
): AsyncAction<boolean> {
  return async (dispatch: Dispatch, getState: ApplicationStateGetter) => {
    let docName = '';
    try {
      const document = getState().orders.documents.find(
        (doc) => doc.id === docId
      );
      if (!document) throw new Error();

      docName = (isDisplayNameEnabled && document.displayName) || document.name;

      const requestResponse = isTemplateInstance
        ? client.templateInstance.deleteDocumentAsync({
            orderId,
            documentId: docId,
          })
        : client.orderDocuments.removeDocument({
            orderId,
            documentId: docId,
          });

      await requestResponse;
      const swipeLeftAction: IOrderDocumentSetRemovingAction = {
        type: OrderTypeKeys.UI_SET_REMOVING_DOCUMENT,
        removingDocumentId: docId,
      };
      dispatch(swipeLeftAction);
      setTimeout(() => {
        const removeDocumentAction: IOrderDocumentRemoveFromCacheAction = {
          type: OrderTypeKeys.REMOVE_CACHED_ORDER_DOCUMENT,
          removedDocumentId: docId,
        };
        dispatch(removeDocumentAction);
        addToast(`${docName} was removed successfully`, 'success')(dispatch);
      }, 500);
      return true;
    } catch (error) {
      addToast(
        `There was an error removing the document. Please try again.`,
        'error'
      )(dispatch);
      return false;
    }
  };
}

export function updateOrderDocument(document: IDocument) {
  return (dispatch) => {
    dispatch({
      type: OrderTypeKeys.UPDATE_ORDER_DOCUMENT,
      document,
    });
  };
}

/**
 * Appends document response above current document
 * @param document - current document being worked on
 * @param appendDoc - document to append to list
 */
export function appendOrderDocument(document: IDocument, appendDoc: IDocument) {
  return (dispatch) => {
    dispatch({
      type: OrderTypeKeys.APPEND_ORDER_DOCUMENT,
      document,
      appendDoc,
    });
  };
}
