import React, { Fragment } from 'react';
import FeatureToggle from './FeatureToggle';
import NotFound from 'views/NotFound';
import { useFeatureFlags } from '@strategicproductdevelopment/fa-feature-flags';

interface IOptions {
  ToggledOffComponent: React.ComponentType<any>;
}

const defaultOpts: IOptions = {
  ToggledOffComponent: NotFound,
};

export default (flag: string, useLaunchDarkly: boolean = false) => (
  WrappedComponent,
  options: IOptions = defaultOpts
) => {
  const { ToggledOffComponent } = options;

  return (props: any) => {
    if (useLaunchDarkly) {
      const { isFeatureEnabled } = useFeatureFlags();
      const isEnabled = useLaunchDarkly ? isFeatureEnabled(flag) : undefined;

      return (
        <Fragment>
          {isEnabled ? (
            <WrappedComponent {...props} />
          ) : (
            <ToggledOffComponent />
          )}
        </Fragment>
      );
    }

    return (
      <Fragment>
        <FeatureToggle flag={flag}>
          <WrappedComponent {...props} />
        </FeatureToggle>
        <FeatureToggle flag={flag} off>
          <ToggledOffComponent />
        </FeatureToggle>
      </Fragment>
    );
  };
};
