import { createQueryString } from 'utils/url';

export interface IViewDocumentUrlQueryParams {
  isDocumentInstance?: string;
  isDocumentTemplate?: string;
  action?: 'stamp' | 'split';
}
export interface IViewHistoryUrlQueryParams {
  type?: 'instance';
}

export default {
  documents: (orderId: string | number) => `/order/${orderId}/documents`,
  viewDocument: (
    orderId: string | number,
    documentId: string | number,
    queryParams?: IViewDocumentUrlQueryParams
  ) =>
    `/order/${orderId}/documents/${documentId}/view${createQueryString({
      ...queryParams,
    })}`,
  uploaded: (orderId: string | undefined) =>
    `/order/${orderId}/documents/uploaded`,
  upload: (orderId: number | string, from?: string) =>
    `/order/${orderId}/documents/upload${from ? `?from=${from}` : ''}`,
  copy: (orderId: number | string) => `/order/${orderId}/documents/copy`,
  create: (orderId: number | string) => `/order/${orderId}/documents/create`,
  customerDocuments: (orderId: string) =>
    `/orders/${orderId}/customerDocuments`,
  viewHistory: (
    orderId: string,
    documentId: string | number,
    queryParams?: IViewHistoryUrlQueryParams
  ) =>
    `/order/${orderId}/documents/${documentId}/history${createQueryString({
      ...queryParams,
    })}`,
  viewDataExtractionDocument: (orderId: string, documentId: string | number) =>
    `/order/${orderId}/documents/idp/data-extraction/${documentId}`,
};
