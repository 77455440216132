import React from 'react';
import styled from 'styled-components';
import { lighterBlue, black, trout } from 'config/theme';
import TextTruncate from './TextTruncate';
import { SMALL_BODY_MEDIUM } from 'config/typography';

const Site_Card_Footer_Max_Width = 500;

export interface ISiteCardFooterProps {
  fileId: number;
  invoiceNumbers?: string;
  checkNumbers?: string;
}

const SiteCardFooter = ({
  fileId,
  invoiceNumbers,
  checkNumbers,
}: ISiteCardFooterProps) => {
  const isMultipleInvoices = invoiceNumbers?.includes('|');
  const invoicesSectionText = isMultipleInvoices ? `Invoices : ` : `Invoice : `;

  const isMultipleChecks = checkNumbers?.includes('|');
  const checksSectionText = isMultipleChecks ? `Checks : ` : `Check : `;

  return invoiceNumbers || checkNumbers ? (
    <Container>
      {invoiceNumbers && (
        <Row>
          <TextTruncate
            classSelectorName={`invoiceNumbers-display-${fileId}`}
            sectionText={invoicesSectionText}
            text={invoiceNumbers}
            maxWidth={Site_Card_Footer_Max_Width}
            tooltipId={`invoiceNumbers-display-${fileId}`}
          />
        </Row>
      )}
      {checkNumbers && invoiceNumbers && <Separator />}
      {checkNumbers && (
        <Row>
          <TextTruncate
            classSelectorName={`checkNumbers-display-${fileId}`}
            sectionText={checksSectionText}
            text={checkNumbers}
            maxWidth={Site_Card_Footer_Max_Width}
            tooltipId={`checkNumbers-display-${fileId}`}
          />
        </Row>
      )}
    </Container>
  ) : null;
};

export default SiteCardFooter;

const Separator = styled.div`
  border-top: 2px solid ${lighterBlue};
  width: 80%;
  height: 1px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 23px;
  max-width: 70%;
  span span {
    ${SMALL_BODY_MEDIUM}
    font-family: Circular;
    &:nth-child(1) {
      color: ${trout};
    }
    &:nth-child(2) {
      color: ${black};
    }
  }
`;

const Container = styled.div`
  border-top: 2px solid ${lighterBlue};
  align-items: start;
  display: flex;
  flex-direction: column;
  flex: 0 0 290px;
  height: 100%;
`;
