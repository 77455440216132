import { Column, Row } from 'components/ui/Box';
import { Content, ExternalLink } from '.';
import {
  FooterHeader,
  FooterLogoLink,
  FooterSection,
  LegalText,
} from './Footer.styles';
import { getCurrentFullYear } from 'utils/date';
import { LogoTitle } from 'components/svg';
import { PolicyBanner } from 'components/PolicyBanner';
import React, { FunctionComponent } from 'react';

const Footer: FunctionComponent = () => {
  const currentYear = getCurrentFullYear();

  return (
    <PolicyBanner>
      <FooterSection>
        <Content gap="medium">
          <FooterHeader>
            <FooterLogoLink tabIndex={13} title="Clarify First Bottom Logo">
              <LogoTitle />
            </FooterLogoLink>

            <Row gap="small">
              <a href="/employee" tabIndex={11} title="employee sign in">
                Employee Sign In
              </a>
              <ExternalLink
                href="https://www.firstam.com/terms-of-use/index.html"
                title="terms of use"
                tabIndex={12}
              >
                Terms of Use
              </ExternalLink>
              <ExternalLink
                href="https://www.firstam.com/privacy-policy/index.html"
                title="privacy policy"
                tabIndex={13}
              >
                Privacy Policy
              </ExternalLink>
              <ExternalLink
                href="https://www.firstam.com/accessibility/"
                title="accessibility statement"
                tabIndex={14}
              >
                Accessibility Statement
              </ExternalLink>
            </Row>
          </FooterHeader>

          <Column gap="small">
            <LegalText>
              Not all ClarityFirst property information and/or features are
              available in all areas or on your mobile device. Fees may apply
              where required by law.
            </LegalText>
            <LegalText>
              &copy;{currentYear} First American Financial Corporation and/or
              its affiliates. All rights reserved. NYSE: FAF
            </LegalText>
            <LegalText>
              First American Title Insurance Company, and the operating
              divisions thereof, make no express or implied warranty respecting
              the information presented and assume no responsibility for errors
              or omissions. First American, the eagle logo, First American
              Title, ClarityFirst and firstam.com are registered trademarks or
              trademarks of First American Financial Corporation and/or its
              affiliates.
            </LegalText>
          </Column>
        </Content>
      </FooterSection>
    </PolicyBanner>
  );
};

export default Footer;
