import { ActivityRightValue } from 'store/permissions/types';
import { IFeatureComponent } from 'store/features/types';

export enum AuthTypeKeys {
  UPDATE_SESSION = 'auth/UPDATE_SESSION',
  UPDATE_SESSION_FEATURES = 'auth/UPDATE_SESSION_FEATURES',
  CHECK_MFA_STATUS = 'auth/CHECK_MFA_STATUS',
  RESET_MFA_STATE = 'auth/RESET_MFA_STATE',
  SET_USER_ACTIVE = 'auth/SET_USER_ACTIVE',
  SET_EMAIL_VERIFICATION_STATUS = 'auth/SET_EMAIL_VERIFICATION_STATUS',
  SET_MFA_STATUS = 'auth/SET_MFA_STATUS',
}

export interface IAuthState {
  loading: boolean;
  session: ISession;
  userPhotoUrl: null | string;
  mfa: IMfa;
  refresh: boolean;
}

export interface ISession {
  authenticated: boolean;
  emailVerificationExpired: boolean;
  firstName: string;
  lastName: string;
  csrfToken: string;
  email: string;
  type: UserType;
  activityRights: ActivityRightValue[];
  features: IFeatureComponent[];
  officeIds: number[];
  primaryOfficeId: number;
  primaryOfficeRegionId: number;
  defaultAccountingOfficeId: number;
  sessionTimeoutUtc: string;
  userId: number;
  primaryOfficeName: string;
}

export enum UserType {
  Internal = 'Internal',
  External = 'External',
  Unregistered = 'Unregistered',
}

export const USER_TYPE_OPTIONS = [
  { title: 'Internal', value: UserType.Internal },
  { title: 'External', value: UserType.External },
];

export interface IGetSession {
  type?: string;
}

export interface IMfa {
  loading: boolean;
  status: number | null;
  conflictType: MfaStatusConflictType | null;
}

export interface ICheckMfaStatus {
  url: string;
}

export enum MfaStatusConflictType {
  Mobile = 'Mobile',
  Email = 'Email',
}

export enum AuthLoadingKeys {
  authSessionTimeoutLoading = 'authSessionTimeoutLoading',
}
