import { useIdentify } from '@strategicproductdevelopment/fa-feature-flags';
import React, { useMemo } from 'react';

interface IFeatureFlagContextWrapperProps {
  isAdmin;
  isInternal;
  isExternal;
  userType;
  userFirstName;
  userLastName;
  userEmail;
  userAuthenticated;
  userId;
  primaryOfficeName;
}

const FeatureFlagContextWrapper: React.FC<IFeatureFlagContextWrapperProps> = (
  props: IFeatureFlagContextWrapperProps
) => {
  const userContext = useMemo(
    () => ({
      kind: 'user',
      key: 'key',
      isAdmin: props.isAdmin,
      isInternal: props.isInternal,
      isExternal: props.isExternal,
      userType: props.userType,
      firstName: props.userFirstName,
      lastName: props.userLastName,
      email: props.userEmail,
      name: props.userFirstName + ' ' + props.userLastName,
      userId: props.userId,
      primaryOfficeName: props.primaryOfficeName,
    }),
    [
      props.isAdmin,
      props.isInternal,
      props.isExternal,
      props.userType,
      props.userFirstName,
      props.userLastName,
      props.userEmail,
      props.userId,
      props.primaryOfficeName,
    ]
  );

  useIdentify(userContext);
  return null;
};

export default FeatureFlagContextWrapper;
