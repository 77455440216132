import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import StyledReactTooltip from 'components/ToolTip/StyledReactTooltip';
import Highlight from './Highlight';

const Ellipsis = styled.span`
  display: block;
  font-family: inherit;
  font-size: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;
Ellipsis.displayName = 'Ellipsis';

export interface ITruncateProps {
  classSelectorName?: string;
  maxWidth?: number;
  text: string;
  tooltipId?: string;
  sectionText?: string;
}

const TextTruncate = ({
  classSelectorName,
  maxWidth,
  text,
  tooltipId,
  sectionText,
}: ITruncateProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [plainText, setPlainText] = useState('');

  useEffect(() => {
    setPlainText(simpleHtmlStrip(text));
  }, [text, setPlainText, simpleHtmlStrip]);
  return (
    <>
      <Ellipsis
        ref={(ref: HTMLSpanElement) => {
          if (!maxWidth) return;
          const newShowTooltip = ref?.offsetWidth >= maxWidth;
          if (newShowTooltip !== showTooltip) {
            setShowTooltip(newShowTooltip);
          }
        }}
        className={
          classSelectorName
            ? `${classSelectorName}__ellipsis-wrapper`
            : undefined
        }
      >
        <span data-for={tooltipId} data-tip={plainText}>
          {sectionText && <span>{sectionText}</span>}
          <Highlight text={text} />
        </span>
      </Ellipsis>

      {showTooltip && (
        <StyledReactTooltip
          effect={'float'}
          id={tooltipId}
          textAlign="center"
        />
      )}
    </>
  );
};
TextTruncate.displayName = 'TextTruncate';

export default TextTruncate;

export const simpleHtmlStrip = (htmlString: string): string => {
  const div = document.createElement('div');
  div.innerHTML = htmlString;
  return div.textContent || div.innerText || '';
};
