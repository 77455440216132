import styled from 'styled-components';
import Chevron from 'icons/ChevronIcon';
import {
  blue,
  rowHoverColor,
  defaultGrayHover,
  ghostButtonHoverBackground,
  shuttleGrey,
  lighterBlue,
  iconButtonOverlayBackground,
} from 'config/theme';

import Divider from '@material-ui/core/Divider';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

interface ICollapsibleHeaderProps {
  $disablehover?: boolean;
  $headerPaddingLeft?: string;
  $headerPadding?: string;
}

export const ChevronIcon = styled(Chevron)`
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 40px;
  fill: ${shuttleGrey};

  :hover {
    background-color: ${ghostButtonHoverBackground};
  }

  :active {
    background-color: ${defaultGrayHover};
  }
`;

export const CollapsibleHeader = styled(AccordionSummary)<
  ICollapsibleHeaderProps
>`
  padding: ${(props) => (props.$headerPadding ? props.$headerPadding : '24px')};

  &:hover {
    background: ${(props) =>
      props.$disablehover ? '' : iconButtonOverlayBackground};
  }

  :active {
    background-color: ${(props) => (props.$disablehover ? '' : rowHoverColor)};
  }

  .MuiAccordionSummary-content {
    align-items: center;
    margin: 0;

    padding-left: ${(props) =>
      props.$headerPaddingLeft
        ? props.$headerPaddingLeft
        : props.expandIcon === null && '40px'};

    div {
      &.accordion {
        &__title {
          > * {
            margin: 0;
            color: ${blue};
            font-family: 'Circular', sans-serif;
            font-style: normal;
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.15%;
          }
        }
      }
    }
  }

  .MuiButtonBase-root {
    position: absolute;
    right: 24px;

    &.MuiAccordionSummary-expandIcon {
      span.MuiIconButton-label {
        transform: rotate(180deg);

        svg {
          height: 40px;
          width: 40px;
          margin-top: 8px;
          padding-left: 14px;
          padding-top: 14px;
        }
      }
    }
  }

  .MuiIconButton-root {
    padding: 0;
  }
`;

export const CollapsibleDivider = styled(Divider)`
  background: ${lighterBlue};
`;

export const CollapsibleContent = styled(AccordionDetails)`
  padding: 24px;
`;
