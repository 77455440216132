import { AuthTypeKeys, IAuthState, UserType } from './types';
import userPlaceholderImage from 'images/user-placeholder.jpg';

export const initialState: IAuthState = {
  loading: true,
  session: {
    userId: 0,
    authenticated: false,
    emailVerificationExpired: false,
    type: '' as UserType,
    firstName: '',
    lastName: '',
    csrfToken: '',
    email: '',
    activityRights: [],
    features: [],
    officeIds: [],
    primaryOfficeId: 0,
    primaryOfficeName: '',
    primaryOfficeRegionId: 0,
    defaultAccountingOfficeId: 0,
    sessionTimeoutUtc: '',
  },
  userPhotoUrl: userPlaceholderImage,
  mfa: {
    loading: false,
    status: null,
    conflictType: null,
  },
  // We want to refresh sessions of full reload, so by default we start out with 'true'
  refresh: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AuthTypeKeys.UPDATE_SESSION: {
      const { refreshed } = action;
      // If we refreshed on our last call, we want to set refresh to false,
      // otherwise leave it untouched
      const refresh = refreshed ? false : state.refresh;

      if (action.session?.authenticated) {
        // Exposing accessToken and userInfo so it can be used by the Relay FE app
        window.accessToken = action.session.accessToken;
        window.userInfo = {
          activityRights: Object.freeze([
            ...(action.session.activityRights || []),
          ]),
          userId: action.session.userId,
        };
        // Freeze the object to prevent it from being mutated via JS
        Object.freeze(window.userInfo);
      }

      return {
        ...state,
        loading: false,
        session: {
          ...action.session,
          features: state.session?.features,
        },
        refresh,
      };
    }
    case AuthTypeKeys.UPDATE_SESSION_FEATURES: {
      return {
        ...state,
        session: {
          ...state.session,
          features: action.features,
        },
      };
    }
    case AuthTypeKeys.SET_USER_ACTIVE: {
      return {
        ...state,
        refresh: true,
      };
    }
    case AuthTypeKeys.CHECK_MFA_STATUS:
      return {
        ...state,
        mfa: {
          ...state.mfa,
          loading: true,
        },
      };
    case AuthTypeKeys.SET_MFA_STATUS:
      return {
        ...state,
        mfa: {
          loading: false,
          status: action.status,
          conflictType: action.conflictType,
        },
      };
    case AuthTypeKeys.RESET_MFA_STATE:
      return {
        ...state,
        mfa: {
          loading: false,
          status: null,
          conflictType: null,
        },
      };
    default:
      return state;
  }
};
