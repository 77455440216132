import React from 'react';
import { connect } from 'react-redux';
import store, { IApplicationState } from 'store';
import styled from 'styled-components';
import { black, blue, subheaderZIndex } from 'config/theme';
import { DropdownRowContainer, Header, IconContainer, Title } from './Common';
import { FeatureToggle } from 'components/Features';
import {
  OfficeViewFeatures,
  ChecklistsFeatures,
  DepositsFeatures,
} from 'store/features/types';
import {
  setOfficeViewSearchValue,
  setFilterCardStatus,
} from 'store/orders/actions';
import { ActivityRightValue } from 'store/permissions/types';
import { push } from 'connected-react-router';
import { OFFICE_ID_LOCAL_STORAGE_KEY } from 'components/Accounting/AccountingContainer';
import accountingUrls from 'config/urls/accounting';
import { IMMUTABLE_HTML_ATTRIBUTE, REGION_ID } from 'utils/constants';
import MoneyOut from 'icons/MoneyOut';
import MoneyIn from 'icons/MoneyIn';
import CurrencyUsdBox from 'icons/CurrencyUsdBox';
import DocumentOutline from 'icons/DocumentOutline';
import CheckFilledOutline from 'icons/CheckFilledOutline';
import FileExportOutline from 'icons/FileExportOutline';
import Overdraft from 'icons/Overdraft';
import { isFeatureEnabled } from 'store/features/helpers';
import { MenuOption } from 'components/Accounting/IncomingFunds/constants';
import AddIcon from 'icons/AddIcon';

interface IAccountingDropdownProps {
  closeDropdown: () => void;
  primaryOfficeId: number;
  primaryOfficeRegionId: number;
  defaultAccountingOfficeId: number;
  isTemplateManager: boolean;
  isAchDepositEnabled: boolean;
  push: (url: string) => void;
}

const mapStateToProps = (state: IApplicationState) => ({
  primaryOfficeId: state.auth.session.primaryOfficeId,
  primaryOfficeRegionId: state.auth.session.primaryOfficeRegionId,
  defaultAccountingOfficeId: state.auth.session.defaultAccountingOfficeId,
  isTemplateManager: state.auth.session.activityRights.includes(
    ActivityRightValue.TemplateManagement
  ),
  isAchDepositEnabled: isFeatureEnabled(state, DepositsFeatures.AchDeposit),
});

export default connect(mapStateToProps, { push })(function AccountingDropdown({
  closeDropdown,
  primaryOfficeId,
  primaryOfficeRegionId,
  defaultAccountingOfficeId,
  isTemplateManager,
  push,
  isAchDepositEnabled,
}: IAccountingDropdownProps) {
  const officeId =
    localStorage.getItem(OFFICE_ID_LOCAL_STORAGE_KEY) ||
    primaryOfficeRegionId === REGION_ID
      ? primaryOfficeId
      : defaultAccountingOfficeId;

  const overdraftApproval = 'Overdraft Approval';

  const RenderItem = (item: any): JSX.Element => {
    return (
      <DropdownRowContainer
        id={item.id}
        href={item.url}
        target={item.description === overdraftApproval ? '_blank' : ''}
        noBorder
        onClick={(e) => {
          if (item.description === overdraftApproval) {
            closeDropdown();
          } else {
            e.preventDefault();
            push(item.url);
            closeDropdown();
            if (item.description === 'External Uploads') {
              store.dispatch(setOfficeViewSearchValue(''));
              store.dispatch(setFilterCardStatus(''));
            }
          }
        }}
        style={{ cursor: 'pointer' }}
        {...{ [IMMUTABLE_HTML_ATTRIBUTE]: item.dataId }}
      >
        <IconContainer>{item.icon}</IconContainer>
        <Description>{item.description}</Description>
      </DropdownRowContainer>
    );
  };
  const menuItems = [
    {
      url: `/accounting/${officeId}/incoming-funds`,
      icon: <MoneyIn fill={blue} />,
      description: isAchDepositEnabled
        ? MenuOption.IncomingFunds
        : MenuOption.IncomingWires,
      feature: OfficeViewFeatures.IncomingWires,
      dataId: 'Accounting__IncomingWires',
      id: 'seo-immutable-office-view-incoming-wires-menu-link',
    },
    {
      url: accountingUrls.outgoingWires(officeId),
      icon: <MoneyOut fill={blue} />,
      dataId: 'Accounting__OutgoingWires',
      description: 'Outgoing Wires',
      feature: OfficeViewFeatures.OutgoingWires,
      id: 'seo-immutable-office-view-outgoing-wires-menu-link',
    },
    {
      url: `/office/${officeId}/checklists`,
      icon: <CheckFilledOutline fill={blue} />,
      description: 'Checklists',
      feature: ChecklistsFeatures.CustomerChecklistTemplates,
      id: 'seo-immutable-office-view-checklists-menu-link',
    },
    {
      url: `/office/${officeId}/customer-uploads`,
      icon: <DocumentOutline />,
      description: 'Customer Uploads',
      id: 'seo-immutable-office-view-customer-uploads-menu-link',
    },
    {
      url: accountingUrls.ibaTransactions(officeId),
      icon: <CurrencyUsdBox fill={blue} />,
      description: 'IBA Transactions',
      id: 'seo-immutable-office-view-iba-transactions-menu-link',
    },
    {
      url: accountingUrls.trustAccounting(officeId),
      icon: <FileExportOutline fill={blue} />,
      description: 'Trust Accounting',
      feature: OfficeViewFeatures.TrustExtractOverview,
      id: 'seo-immutable-office-view-trust-accounting-menu-link',
    },
    {
      url: AppConfig.fastWorkspaceUrl,
      icon: <Overdraft fill={blue} />,
      description: overdraftApproval,
      id: 'seo-immutable-office-view-overdraft-approval-menu-link',
    },
    {
      url: `/accounting/${officeId}/deposit-list`,
      icon: (
        <AddIcon
          fill={blue}
          style={{
            position: 'relative',
            top: '7px',
            left: '5px',
          }}
        />
      ),
      description: 'Deposit List',
      feature: OfficeViewFeatures.DepositList,
      id: 'seo-immutable-office-view-deposit-list-menu-link',
    },
  ]
    .filter((dropdown) => {
      if (!isTemplateManager) return dropdown.description !== 'Checklists';
      else return dropdown;
    })
    .map((item, index) => (
      <React.Fragment key={index}>
        {!item?.feature ? (
          RenderItem(item)
        ) : (
          <FeatureToggle flag={item.feature} key={index}>
            {RenderItem(item)}
          </FeatureToggle>
        )}
      </React.Fragment>
    ));

  return (
    <div style={{ zIndex: subheaderZIndex + 1 }} key={'Accounting_Dropdown'}>
      <Header>
        <Title>Office</Title>
      </Header>
      {menuItems}
      {/* TODO: uncomment below once those are implemented */}
      {/*<DropdownRowContainer as="div" noBorder>
        <IconContainer>
          <MoneyInIcon fill={blue} />
        </IconContainer>
        <Description>Deposits</Description>
      </DropdownRowContainer>
      <DropdownRowContainer as="div" noBorder>
        <IconContainer>
          <MoneyOutIcon fill={blue} />
        </IconContainer>
        <Description>Disbursements</Description>
      </DropdownRowContainer>*/}
    </div>
  );
});

const Description = styled.div`
  color: ${black};
  padding: 7px 0px 8px 14px;
`;
Description.displayName = 'Description';

const SuperSymbols = styled.span`
  font-size: 12px;
  font-style: normal;
  text-align: center;
  letter-spacing: 0.005em;
  vertical-align: super;
`;
SuperSymbols.displayName = 'SuperSymbols';
