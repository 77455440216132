import { getSessionInStore } from 'store/injector';
import { getCorrelationContext } from 'utils/appInsights';
import { isIE10, isIE11 } from 'utils/browser';

// IE10 and IE11 cache concecutive requests to the same
// URL and as result sometimes no requests are being
// triggered, thus making the app to look unresponsive.
// This interceptor adds a `t` param with a timestamp
// to the URL in order to trick the browser.
const reqInterceptIE11 = (config) => {
  if (isIE11() || isIE10()) {
    const t = +new Date();
    const params = { ...config.params, t };
    return { ...config, params };
  } else {
    return config;
  }
};

const reqInterceptCSRF = (config) => {
  const session = getSessionInStore();
  if (session && session.csrfToken) {
    const { csrfToken } = session;
    config.headers.common['X-CSRF-TOKEN'] = csrfToken;
  }
  return config;
};

const reqInterceptAppInsights = (config) => {
  const aiContext = getCorrelationContext();
  if (!aiContext) return config;

  const {
    telemetryTrace: { traceID: operationID },
  } = aiContext || {};

  const sessionID = aiContext.getSessionId();

  if (sessionID) {
    config.headers.common['X-SESSION-ID'] = sessionID;
  }

  if (operationID) {
    config.headers.common['X-OPERATION-ID'] = operationID;
  }

  return config;
};

export { reqInterceptAppInsights, reqInterceptCSRF, reqInterceptIE11 };
